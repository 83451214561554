<template>
  <div class="page">
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import PopView from "@/components/PopView.vue";

export default {
  components: {
    PopView,    
  },
  data() {
    return {
      
    };
  },
  activated() {
    window.scrollTo(0, 0);
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.page {
  margin-bottom: 45px;
  // background: #fff;
}
.el-select{
  width: 160px;
  margin-left:15px ;
}
</style>